// const
import './partials/load-images.js'
import './libs/aos.js'
import './partials/blog-slider.js'
import './partials/scrollable-line.js'

//
//* ANIMATION
//

function animation() {
  var path = document.querySelectorAll('.animation path');
  var percentScroll0; 
  var percentScroll1; 
  var percentScroll2; 
  var fastPaths = document.querySelectorAll('.animation__single--fast path');
  var slowPaths = document.querySelectorAll('.animation__single--slow path');
  var windowHeight = window.innerHeight;
  var highlightPaths = document.querySelectorAll('.animation__single--highlight path');

  path.forEach((e) => {
      e.style.strokeDasharray = e.getTotalLength();
      e.style.strokeDashoffset = e.getTotalLength();
  });

  document.addEventListener('wheel', (evt) => {
    percentScroll0 = window.scrollY / (document.body.offsetHeight - windowHeight);
    percentScroll1 = window.scrollY / (document.body.offsetHeight / 2 - windowHeight);

    if(percentScroll1 > 1) percentScroll1 = 1;

    fastPaths.forEach((e) => {
        e.style.strokeDashoffset = Math.floor(e.getTotalLength() * (1 - percentScroll0));
    });

    slowPaths.forEach((e) => {
        e.style.strokeDashoffset = Math.floor(e.getTotalLength() * (1 - percentScroll1));
    });

    highlightPaths.forEach((e) => {
        e.style.strokeDashoffset = Math.floor(e.getTotalLength() * (1 - percentScroll0));
    });
  }, {
    capture: true,
    passive: true
  })

}

animation();

//
//* NAV
//

document.querySelector('.nav__content__hamburger').addEventListener('click', () => {
    document.querySelector('.nav__content__links').classList.toggle('active');
    document.querySelector('.news').classList.toggle('hidden');
});

document.querySelectorAll('.nav__content__links a').forEach((el) => {
    el.addEventListener('click', () => {
        document.querySelector('.nav__content__links').classList.remove('active');
        document.querySelector('.nav__content__hamburger').classList.remove('opened');
    });
});

//
//* FOOTER
//

const footerSwiper = new Swiper('.footerSwiper', {
    slidesPerView: 'auto',
    autoplay: {
        delay: 1,
        disableOnInteraction: false
      },
      speed: 1500,
    spaceBetween: 30,
    loop: true,
    breakpoints: {
        695: {
          spaceBetween: 57,
        },
        1280: {
          spaceBetween: 50,
        },
        1720: {
          spaceBetween: 76,
        }
      },
});


function clearStyles() {
  document.querySelectorAll('.ml-embedded style').forEach((sheet) => {
    sheet.remove();
  });
  if(window.screen.width < 696) {
    document.querySelector('.ml-form-embedSubmit .primary').innerHTML = "<img src='https://api-verse.com/wp-content/themes/api-verse/assets/images/send-icon.png' alt='OK'>";
  }
}

setTimeout(clearStyles, 1500);

if(document.querySelectorAll('.blog__content__tile').length == 0) {
  document.querySelector('.blog').remove();
  document.querySelector('.news').remove();
} 


