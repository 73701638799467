

// var multiplier = 1;
// const endPosition = document.querySelector('.scrollable-line').offsetHeight - document.querySelector('.scrollable-line__main').offsetHeight;
// const factor = 0.75/endPosition;
// const gradient = document.querySelector('.scrollable-line__main .gradient');
// const dynamic = document.querySelector('.dynamic-content');
// const mainEl = document.querySelector('.scrollable-line__main');

// function scale() {
//     var elDistanceToTop = mainEl.offsetTop;

//     multiplier = elDistanceToTop * factor + 0.25;
//     console.log(multiplier);
//     if(multiplier == 0) {0
//         mainEl.style.transform = `scale(0.25)`;
//         return;
//     }
//     if(multiplier >= 0.98) {
//         mainEl.style.transform = `scale(1)`;
//         document.querySelector('.scrollable-line__fixed').classList.add('visible');
//         return;
//     } else {
//         document.querySelector('.scrollable-line__fixed').classList.remove('visible');
//     }
//     if(multiplier > 0.25)
//     mainEl.style.transform = `scale(${multiplier})`;
// }

// function changeContent() {
//     if(multiplier == 0.25) {
//         gradient.innerHTML = "Your first choice "
//         document.querySelector('.dynamic-content').innerHTML = "to integrate with blockchain."
//     }
//     else if( multiplier > 0.25 && multiplier <= 0.4375) {
//         gradient.innerHTML = "Your first choice "
//         dynamic.innerHTML = "to integrate with blockchain."
//     } else if (multiplier > 0.4375 && multiplier <= 0.625) {
//         gradient.innerHTML = "Your first choice "
//         dynamic.innerHTML = "to collect the data."
//     } else if (multiplier > 0.625 && multiplier <= 0.8125) {
//         gradient.innerHTML = "Your first choice "
//         dynamic.innerHTML = "to build your dApp."
//     } else if (multiplier > 0.8125) {
//         dynamic.innerHTML = ""
//         gradient.innerHTML = "Your first choice."
//     }
// }

// scale();
// changeContent();
// const ENABLE_HOVER_DELAY = 500;  
// let timer;

// window.addEventListener("scroll", (event) => {
//     const bodyClassList = document.body.classList;
//     clearTimeout(timer);
      
//     if (!bodyClassList.contains('disable-hover')) {
//       bodyClassList.add('disable-hover');
//     }

//     scale();
//     changeContent();
          
//     timer = setTimeout(function() {
//       bodyClassList.remove('disable-hover');
//     }, ENABLE_HOVER_DELAY);


// }, {passive: true});

