const swiper = new Swiper('.blogSwiper', {
    slidesPerView: 'auto',
    spaceBetween: 59,
  
    // Navigation arrows
    navigation: {
      nextEl: '.blogSwiper .swiper-button-next',
      prevEl: '.blogSwiper .swiper-button-prev',
    },
  
  });