function loadImages() {
    const allImages = document.querySelectorAll('img[data-src], source[data-src]');
    const allImagesScrset = document.querySelectorAll('img[data-srcset], source[data-srcset]');
    if(allImages)
    allImages.forEach((img) => {
        img.setAttribute('src', img.getAttribute('data-src'));
    });
    if(allImagesScrset)
    allImagesScrset.forEach((img) => {
        img.setAttribute('srcset', img.getAttribute('data-srcset'));
    });
}

loadImages();